import React from "react";
import Card from "react-bootstrap/Card";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./index.css";

const DropDownCard = ({ question, answer, isActive, onClick }) => {
  return (
    <Card className="mb-2 no-border">
      <Card.Header
        onClick={onClick}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <span className="question" style={{ flex: 1 }}>
          {question}
        </span>
        {isActive ? <FaChevronUp /> : <FaChevronDown />}{" "}
      </Card.Header>
      {isActive && <Card.Body className="answer">{answer}</Card.Body>}
    </Card>
  );
};

export default DropDownCard;
