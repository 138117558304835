import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import ContactForm from "../../../../COMPONENTS/FORM/CONTACT";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const ContactSection = () => {
  return (
    <Container fluid className="contact-form-section">
      <Row className="contact-content">
        <Col xs={12} md={6}>
          <h3 className="contact-heading">Get in Touch</h3>
          <p className="contact-info">
            <FontAwesomeIcon icon={faPhone} /> (866) 300-5380
          </p>
          <p className="contact-info">
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:info@kurr.com">info@kurr.com</a>
          </p>
          <div className="addressSection">
            <h4 className="addressTitle">Our Offices</h4>
            <address className="address">
              2425 Cedar Springs Rd
              <br />
              Dallas, Texas 75201
            </address>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <h4 className="contact-email">E-mail Us</h4>
          <p>
            If you have any questions or are interested in adding clinical
            trials to your urgent care center or private practice, call or
            e-mail us. We are happy to speak with you further about how clinical
            trials can impact your practice.
          </p>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSection;
