import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStethoscope,
  faUserGroup,
  faUser,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const statementsData = [
  {
    icon: faStethoscope,
    description: (
      <>
        To improve our <strong>PATIENTS</strong> lives with the most advanced
        medical treatments, superior healthcare oversight, and education about
        their medical conditions.
      </>
    ),
  },
  {
    icon: faUser,
    description: (
      <>
        To provide our <strong>PHYSICIANS</strong> with the highest quality
        clinical support and an opportunity to directly aid in the development
        of the latest medical treatments and technologies.
      </>
    ),
  },
  {
    icon: faUserGroup,
    description: (
      <>
        To give our <strong>EMPLOYEES</strong> unlimited growth potential and a
        company they can be proud of.
      </>
    ),
  },
  {
    icon: faSignal,
    description: (
      <>
        To deliver our <strong>SPONSORS</strong> a seamless experience in the
        advancement of clinical research by consistently delivering quality
        data, fast enrollment, and reliability.
      </>
    ),
  },
];

const Statements = () => {
  return (
    <Container className="text">
      <div className="background-image" />
      <Row className="h-100">
        {statementsData.map((item, index) => (
          <Col
            xs={12}
            md={6}
            key={index}
            className={`mb-4 ${index % 2 === 1 ? "highlight" : ""}`}
          >
            <div className="statementCard d-flex flex-column flex-md-row align-items-center">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={item.icon} className="icon" />
              </div>
              <p className="description">{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Statements;
