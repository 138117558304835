import { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import "./index.css";
import ucctLogoColour from "../../ASSETS/ucct-colour-logo.webp";

const NavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown on click outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar>
      <Container
        fluid
        className="d-flex justify-content-between align-items-center "
      >
        <Navbar.Brand>
          <a href="/">
            <img
              alt="ucct Brand Logo"
              src={ucctLogoColour}
              className="ucct-brand-logo"
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className="ms-auto" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/patients">PATIENTS</Nav.Link>
            <Nav.Link href="/physicians">PHYSICIANS</Nav.Link>
            <Nav.Link href="/sponsors">SPONSORS</Nav.Link>
            <NavDropdown
              title="ABOUT"
              id="basic-nav-dropdown"
              show={showDropdown}
              onToggle={(isOpen) => setShowDropdown(isOpen)}
              ref={dropdownRef}
              drop="down" // Set this prop to make the dropdown open to the left
            >
              <NavDropdown title="UCC TRIALS" id="nested-nav-dropdown">
                <NavDropdown.Item href="/mission-statement">
                  MISSION STATEMENT
                </NavDropdown.Item>
                <NavDropdown.Item href="/job-application">
                  JOB APPLICATION
                </NavDropdown.Item>
                <NavDropdown.Item href="/responsibilities">
                  RESPONSIBILITES
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="SERVICES" id="nested-nav-dropdown">
                <NavDropdown.Item href="/assessment">
                  ASSESSMENT
                </NavDropdown.Item>
                <NavDropdown.Item href="/implementation">
                  IMPLEMENTATION
                </NavDropdown.Item>
                <NavDropdown.Item href="/management">
                  MANAGEMENT
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>
            <Nav.Link href="/contact">CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
