import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

const ContactSection = () => {
  return (
    <div className="management-contact-section">
      {" "}
      <div className="management-contact-overlay" />{" "}
      <Container className="management-contact-content">
        <Row>
          <Col xs={12}>
            <h2 className="management-contact-heading">
              Don’t see your answers? Ask Us:
            </h2>
            <div className="btnSection">
              <a href="/contact">
                <button className="management-contact-button">
                  Contact Us
                </button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
