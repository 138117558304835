// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../ASSETS//map-bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-participate-title {
  color: #364776;
  line-height: 1.2;
  font-size: 36px;
  padding: 2em;
  text-align: center;
}
.why-participate-section {
  background-color: #f9f9f9;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: 0;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/PHYSICIANS/SECTIONS/WHY_PARTICIPATE/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,yDAAwD;EACxD,oBAAoB;EACpB,4BAA4B;EAC5B,iCAAiC;EACjC,UAAU;AACZ","sourcesContent":[".why-participate-title {\n  color: #364776;\n  line-height: 1.2;\n  font-size: 36px;\n  padding: 2em;\n  text-align: center;\n}\n.why-participate-section {\n  background-color: #f9f9f9;\n  background-image: url(\"../../../../ASSETS//map-bg.webp\");\n  background-size: 70%;\n  background-repeat: no-repeat;\n  background-position: right bottom;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
