import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

const QuotesSection = () => {
  return (
    <Container fluid className="quote-container">
      {/* Background Image */}
      <div className="quote-background-image" />

      {/* Overlay to darken the background */}
      <div className="quotes-overlay" />

      {/* Row & Col for the content */}
      <Row className="h-100 align-items-center justify-content-center">
        <div className="quote-section">
          <h2 className="quote-text">
            “Quality is not an act, it is a habit.”
          </h2>
          <h4 className="author">~ Aristotle</h4>
        </div>
      </Row>
    </Container>
  );
};

export default QuotesSection;
