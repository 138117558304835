import React from "react";
import "./index.css";
import ContactSection from "./SECTIONS/CONTACT";
import ManagementHeroSection from "./SECTIONS/HERO";
import ManagementStatements from "./SECTIONS/STATEMENTS";

const ManagementPage = () => {
  return (
    <>
      <ManagementHeroSection />
      <ManagementStatements />
      <ContactSection />
    </>
  );
};

export default ManagementPage;
