import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

const ResponsibilitiesStatements = () => {
  return (
    <Container className="resposibilities-text">
      <div className="background-image" />
      <Row className="h-100">
        Having an understanding of responsibilities in the beginning is often
        key to success. It is important to understand, ultimately, the primary
        source of patients that are going to participate in any clinical study
        will be recruited from the physicians practice. So, it’s very important
        that the entire staff at the practice is aware of the research and is
        trained on how to appropriately approach potential research patients.
        One of the benefits to running trials in urgent care centers is the
        constant influx of new patients. Ultimately, it is the physician that is
        responsible for the outcome for the trial and is responsible for
        following Good Clinical Practices, treatment of patients, collecting all
        the necessary data required to complete the case report forms or source
        documents, and collect all adverse events in accordance with specific
        guidelines. Additionally, the physicians practice must conduct each
        trial in accordance within sponsor instructions, FDA regulations, and
        communicate effectively with our staff and sponsors when needed.
        Communication is key to a successful trial and turn around time for
        documents must be less than 72 hours. UCC Trials is here to make all of
        the clinical processes seamless and provide the highest quality clinical
        support for our physicians.
        <br /> <br /> Along with understanding responsibilities, having an
        appropriate expectation is equally important to us. When we collectively
        initiate the research program, we will not be seeing patients tomorrow.
        It typically takes 60-90 days before a new site is accepted for a trial.
        However, this does differ based on a lot of factors including the
        physicians experience and the availability of new trials.
        <br /> <br />
        We look forward to working with you and your medical staff.
        <br /> <br /> ~UCC Trials
      </Row>
    </Container>
  );
};

export default ResponsibilitiesStatements;
