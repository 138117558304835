import React from "react";
import Card from "react-bootstrap/Card";
import "./index.css"; // Ensure you import the correct CSS file
import { Col } from "react-bootstrap";

const CustomCard = ({ key, title, content, btnLink, background }) => {
  return (
    <Col xs={12} md={4} className="text-container p-0">
      {" "}
      {/* Added p-0 for no padding */}
      <Card
        className="custom-card"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
      >
        <Card.Body className="custom-card-body">
          <Card.Title className="custom-card-title">{title}</Card.Title>
          <Card.Text className="custom-card-subtitle">{content}</Card.Text>
          <div className="btnSection">
            <a href={{ btnLink }}>
              <button className="stepsButton">Learn More</button>
            </a>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomCard;
