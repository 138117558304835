import React from "react";
import "./index.css";
import InvestigativeSiteSection from "./SECTIONS/INVESTIGATIVE_SITE_NETWORK";
import VolunteersSection from "./SECTIONS/VOLUNTEERS";
import ContactSection from "./SECTIONS/CONTACT";
import ContactHeroSection from "./SECTIONS/HERO";

const PatientsPage = () => {
  return (
    <>
      <ContactHeroSection />
      <InvestigativeSiteSection />
      <VolunteersSection />
      <ContactSection />
    </>
  );
};

export default PatientsPage;
