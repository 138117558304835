import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Ensure this imports your CSS
import PhysicianImageCard from "./../../../../COMPONENTS/COMMON/CARD/PHYSICIAN IMAGE CARDS";
import stethescopeImage from "../../../../ASSETS/stethescopeImage.webp";
import microscopeImage from "../../../../ASSETS/microscope.jpg";
import ucctMeetingImg from "../../../../ASSETS/ucct-meeting.webp";

const WhyPartnerSection = () => {
  const physicians = [
    {
      image: stethescopeImage,
      title: "Assess",
      content:
        "The first action we will take with a new perspective partnership is assess the physician and practice to determine if clinical research is an appropriate fit. We gather information in regards to the scope and potential impact of gaps or deficiencies concerning the delivery of care in clinical research and identity constructive matrices for a successful trial in your specific practice.",
      link: "/link1",
    },
    {
      image: microscopeImage,
      title: "Implement",
      content:
        "After we have an agreement in place, it’s time to start implementing the research program. We will take a deeper look into the patient population at the practice to determine what clinical trials are best for for the practice. During this time as well, we will set up the research office within the practice and begin our hiring process for any new UCC Trials staff.",
      link: "/link2",
    },
    {
      image: ucctMeetingImg,
      title: "Manage",
      content:
        "UCC Trials will provide daily onsite management for each study from application to study close out. We will acquire trials, negotiate contracts, manage financials and regulatory, facilitate every sponsor visits, explain consent with patients, document SAE’s, IND reports, and amendments, provide scheduling for all patients, and more to ensure enrollment goals are met.",
      link: "/link3",
    },
  ];
  return (
    <Container className="physicianSection">
      <Row className="h-100 align-items-center">
        {physicians.map((physician, index) => (
          <Col key={index} xs={12} md={4}>
            <PhysicianImageCard
              key={index}
              title={physician.title}
              content={physician.content}
              link={physician.link}
              image={physician.image}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default WhyPartnerSection;
