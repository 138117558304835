import React from "react";
import "./index.css";
import Statements from "./SECTIONS/STATEMENTS";
import ContactSection from "./SECTIONS/CONTACT";
import MissionStatementHeroSection from "./SECTIONS/HERO";
import QuotesSection from "./SECTIONS/QUOTES";

const MissionStatementPage = () => {
  return (
    <>
      <MissionStatementHeroSection />
      <Statements />
      <QuotesSection />
      <ContactSection />
    </>
  );
};

export default MissionStatementPage;
