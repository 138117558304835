import React from "react";
import Card from "react-bootstrap/Card";
import "./index.css"; // Ensure you import the correct CSS file
import { Col } from "react-bootstrap";

const PhysicianImageCard = ({ index, title, content, btnLink, image }) => {
  return (
    <Card key={index} className="customCard">
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
      <Card.Body>
        {/* <Card.Link className="physicianImageBtn" href={btnLink}>
          Learn More
        </Card.Link> */}
      </Card.Body>
    </Card>
  );
};

export default PhysicianImageCard;
