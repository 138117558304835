import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Ensure this imports your CSS
import WhyCard from "../../../../COMPONENTS/COMMON/CARD/WHY CARDS";

const cards = [
  {
    title: "Our Network",
    content:
      "Our network is designed to be effective and efficient. When considering a partnership with an urgent care or private practice, there are a number of factors that determine our final decision including location, population, years open, physicians, support staff, and much, much more. We want to ensure successful outcomes for our sponsors and sites alike. Almost all of the partnering urgent care centers have multiple locations within a short radius. This greatly improves our available patient population making for even faster enrollment for each study.",
  },
  {
    title: "Central Clinical Trials Management System",
    content:
      "All informaiton managed by Urgent Care Clinical Trials is done through our web based central CTMS to ensure quality, accuracy and effectiveness. We are able to accurately track budgets, costs, and invoices to ensure an accurate financial outcome for each study. Keep digital archives of every document, including every amendment to track any document changes. Store our SOP’s for easy access to everyone involved with each study. We also have the capability to grant remote access to site monitors to make better use of their time during site visits. All of our information is stored in professional data centers that are HIPAA compliant with enterprise class data encryption to ensure our data is safe and secure.",
  },
  {
    title: "Patient Management",
    content:
      "Urgent Care Clinical Trials manages each patient throughout their time with us. Starting with our proprietary database of patients, we are able to properly store patient information as well as efficiently search out database by age, gender, BMI, inclusive/exclusive medical conditions, inclusive/exclusive medications and more. This allows us to quickly identify and enroll patients into studies. We are also able to schedule each patient visit through our system and all of our patients receive SMS or text message reming them of their next scheduled appointment 24 hours and 1 hour before. This has been shown to keep more patients in studies for longer periods of time and greatly reduces no shows. Additionally, all of our patients that receive stipends, will do so at the end of every visit through our pay card system. Patients are paid immediately and receive a text message confirming funds have been received! Having the proper systems in place to make patient management fast, easy, and simple is key to a successful trial.",
  },
  {
    title: "Quality Assurance",
    content:
      "We are constantly monitoring our quality indicators and providing continuing education and training for all of our research staff. We are able to remotely monitor evaluate data collection, regulatory documents, monitor feedback, and equipment maintenance, as well as track expirations of and medical licenses, CV’s, and certifications for all of our research staff.",
  },
  {
    title: "Central Marketing",
    content:
      "Urgent Care Clinical Trials has an in-house marketing agency that designs and submits all of our marketing campaigns for IRB approval. We enroll patients from a verity of digital, print, and media sources along with numerous community out reach programs.",
  },
  {
    title: "One Company, One Solution",
    content:
      "One of the many benefits of working with Urgent Care Clinical Trials is there is one company to work with on budgets and contracts. You will not have to negotiate with every site, only one time, with us.",
  },
];
const WhyParticipateSection = () => {
  return (
    <div className="why-participate-section">
      <Container>
        <Row>
          <h2 className="why-participate-title">
            {" "}
            Urgent Care Clinical Trials – Investigative Site Network
          </h2>
          <p className="why-participate-subtitile">
            Urgent Care Clinical Trials is a clinical research Site Management
            Organization (SMO) developing the first national Investigative Site
            Network (ISN) specifically geared to the urgent care industry. We
            not only build clinical research sites within urgent care centers,
            but a variety of private practices as well. By partnering with local
            community based, established urgent cares and physician offices, we
            are able to develop clinical research sites within their practices
            and provide all the necessary resources to successfully run clinical
            research such as:
          </p>
          {cards.map((card, index) => {
            return (
              <Col xs={12} md={6}>
                <WhyCard
                  key={index}
                  title={card.title}
                  content={card.content}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default WhyParticipateSection;
