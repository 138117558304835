import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

const ContactHeroSection = () => {
  return (
    <Container fluid className="contact-hero-section">
      <div className="overlay" />
      <Row className="contact-hero-content">
        <Col xs={12} md={6}>
          {/* <h1 className="contact-hero-Title">Home / Patients</h1> */}
          <h2 className="contact-hero-subtitle">Contact</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactHeroSection;
