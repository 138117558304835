import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Ensure this imports your CSS
import WhyCard from "../../../../COMPONENTS/COMMON/CARD/WHY CARDS";

const cards = [
  {
    title: "Differentiation",
    content:
      "Urgent care centers and private practices that participate in clinical trials will be on the cutting edge of medicine and technology. Our physicians work with investigational treatments that are not yet available and are able to provide their patients access to treatments which they might not otherwise have access to, making our physicians leaders in the medical community.",
  },
  {
    title: "Increase Revenue",
    content:
      "Clinical research can be a tremendous secondary revenue generator for physicians. With competition increasing daily and a push for lower healthcare costs, many physicians are looking to research as an additional revenue source. The revenue associated with a patient visit can be multiple times higher than a private payer rate for a similar treatment. Also, clinical research revenue is not dependent on and insurance payer making for a more diverse revenue stream at a medical practice.",
  },
  {
    title: "Cutting Edge",
    content:
      "Physicians participating in clinical research will be on the cutting edge of new medications and treatments. Our physicians are directly contributing to the advancement of the newest treatments, affecting not only effect participating patients today, but everyone in the world.",
  },
  {
    title: "New Patients",
    content:
      "The primary source of participating patients in clinical research is the physicians practice, however, UCC Trials does marketing campaigns and user other enrollment means to make as many patients aware of the new and available treatments as possible. These new patients will be participating in the research at your practice. Typically, once those patients have participated in research at a new practice, they tend to make that practice their medical home.",
  },
  {
    title: "Your Patients",
    content:
      "By participating in clinical research, our physicians will be able to offer their patients treatments and medications that they would otherwise never have access to at NO COST to the patients. Participating patients will receive medical treatment for their condition at no cost and are typically compensated for their time and travel, making our research program a win, win for everyone participating.",
  },
];
const WhyParticipateSection = () => {
  return (
    <div className="why-participate-section">
      <Container>
        <Row>
          <h2 className="why-participate-title">
            {" "}
            Why physicians decide to participate in clinical research.
          </h2>
          {cards.map((card, index) => {
            return (
              <Col key={index} xs={12} md={6}>
                <WhyCard
                  key={index}
                  title={card.title}
                  content={card.content}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default WhyParticipateSection;
