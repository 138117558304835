// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  height: 80px;
  width: auto;
  z-index: 10;
  font-size: 1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", "Arial", "sans-serif";
  box-shadow: 20px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 0 2em;
}
.ucct-brand-logo {
  height: 40px; /* Adjust as needed for vertical alignment */
}

/* Nav link styles */
.navbar .nav-link {
  color: black;
  padding-bottom: 1em; /* Increased padding for more space */
}

/* Hover/active styles */
.nav-link:hover,
.nav-link:active {
  border-bottom: 1px solid black;
}
/* Dropdown menu styles */
.nav-dropdown-item {
  color: black;
  border: none;
}

.dropdown-menu {
  border: none;
  width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/NAVBAR/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,WAAW;EACX,eAAe;EACf;wCACsC;EACtC,4CAA4C;EAC5C,cAAc;AAChB;AACA;EACE,YAAY,EAAE,4CAA4C;AAC5D;;AAEA,oBAAoB;AACpB;EACE,YAAY;EACZ,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA,wBAAwB;AACxB;;EAEE,8BAA8B;AAChC;AACA,yBAAyB;AACzB;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".navbar {\n  height: 80px;\n  width: auto;\n  z-index: 10;\n  font-size: 1rem;\n  font-family: \"Trebuchet MS\", \"Lucida Sans Unicode\", \"Lucida Grande\",\n    \"Lucida Sans\", \"Arial\", \"sans-serif\";\n  box-shadow: 20px 0px 10px rgba(0, 0, 0, 0.5);\n  padding: 0 2em;\n}\n.ucct-brand-logo {\n  height: 40px; /* Adjust as needed for vertical alignment */\n}\n\n/* Nav link styles */\n.navbar .nav-link {\n  color: black;\n  padding-bottom: 1em; /* Increased padding for more space */\n}\n\n/* Hover/active styles */\n.nav-link:hover,\n.nav-link:active {\n  border-bottom: 1px solid black;\n}\n/* Dropdown menu styles */\n.nav-dropdown-item {\n  color: black;\n  border: none;\n}\n\n.dropdown-menu {\n  border: none;\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
