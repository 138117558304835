import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import heroImg from "../../../../ASSETS/kurr-hero.jpg";

const HeroSection = () => {
  return (
    <Container fluid className="hero-section">
      <img src={heroImg} alt="Hero Banner" className="hero-image" />
      <Row className="heroContent">
        <Col xs={12} md={6}>
          <h1 className="heroTitle">Urgent Care Clinical Trials</h1>
          <p className="heroSubTitle">
            We're pioneering the first national Investigative Site Network (ISN)
            for urgent care. Partnering with local practices, we establish and
            support clinical research sites, providing all necessary resources
            for successful research.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
