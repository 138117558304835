import React from "react";
import Card from "react-bootstrap/Card";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { Col } from "react-bootstrap";

const WhyCard = ({ title, content }) => {
  return (
    <Card className="why-card">
      <Card.Body className="why-card-body">
        <div className="why-card-title-section">
          <FontAwesomeIcon icon={faEarthAmericas} className="why-icon" />
          <Card.Title className="why-card-title">
            <strong>{title}</strong>
          </Card.Title>
        </div>
        <Card.Text className="why-card-content">{content}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default WhyCard;
