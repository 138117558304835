import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faClock } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import ucctBp from "../../../../ASSETS/kurr-bp.jpg";

const ImplementationStatements = () => {
  return (
    <Container fluid className="implementation-text">
      <Row className="h-100">
        <Col xs={12} md={6}>
          <img alt="Patients" src={ucctBp} className="implementationImage" />
        </Col>
        <Col xs={12} md={6}>
          <div className="implementationStatementCard">
            <div className="implementation-icon-wrapper">
              <FontAwesomeIcon icon={faUsers} className="implementationIcon" />
            </div>
            <div className="implementationDescription">
              After we have an agreement in place, it’s time to start
              implementing the research program. We will take a deeper look into
              the patient population at the practice to determine what clinical
              trials are best for the practice. During this time as well, we
              will set up the research office within the practice and begin our
              hiring process for any new UCC Trials staff. It will also be
              extremely important that the practice’s research staff complete
              their trainings provided by UCC Trials during this time in
              preparation for research applications.
            </div>
          </div>
          <div className="implementationStatementCard">
            <div className="implementation-icon-wrapper">
              <FontAwesomeIcon icon={faClock} className="implementationIcon" />
            </div>
            <div className="implementationDescription">
              Once trainings are complete, we will start to apply for trials in
              specialties relevant to your patient population. The application
              process is always very time-sensitive and will consistently
              require less than a 72-hour response from research staff. After an
              application and feasibility have been accepted, we will prepare
              for a Pre-Site Visit from the sponsor. Following the Pre-Site
              Visit, there may be additional training and physician meetings
              pertaining to the specific study. Once completed, the sponsor will
              visit the research site and we will begin enrolling patients into
              the study.
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ImplementationStatements;
