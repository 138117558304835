import React from "react";
import "./index.css";
import ContactSection from "./SECTIONS/CONTACT";
import ResponsibilitiesStatements from "./SECTIONS/STATEMENTS";
import AssessmentHeroSection from "./SECTIONS/HERO";

const AssessmentPage = () => {
  return (
    <>
      <AssessmentHeroSection />
      <ResponsibilitiesStatements />
      <ContactSection />
    </>
  );
};

export default AssessmentPage;
