import React from "react";
import "./index.css";

import ContactSection from "./SECTIONS/CONTACT";
import MissionStatementHeroSection from "./SECTIONS/HERO";
import VolunteersSection from "./SECTIONS/VOLUNTEERS";

const ApplicationsPage = () => {
  return (
    <>
      <MissionStatementHeroSection />
      <VolunteersSection />
      <ContactSection />
    </>
  );
};

export default ApplicationsPage;
