// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../ASSETS/kurr-hero.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-section {
  position: relative;
  color: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.heroContent {
  position: relative;
  z-index: 2;
  padding: 20px;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  text-align: left;
}

@media (max-width: 991px) {
  .hero-section {
    height: auto;
  }

  .hero-image {
    position: static;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .heroContent {
    padding: 40px 20px;
    text-align: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }
}

.heroTitle {
  font-size: 2rem;
  margin-bottom: 10px;
}

.heroSubTitle {
  font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/HERO/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,yDAAyD;EAC3D;AACF;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".hero-section {\n  position: relative;\n  color: black;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n}\n\n.hero-image {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  object-fit: cover;\n  z-index: 1;\n}\n\n.heroContent {\n  position: relative;\n  z-index: 2;\n  padding: 20px;\n  color: rgb(0, 0, 0);\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  height: 100%;\n  text-align: left;\n}\n\n@media (max-width: 991px) {\n  .hero-section {\n    height: auto;\n  }\n\n  .hero-image {\n    position: static;\n    width: 100%;\n    height: auto;\n    z-index: 1;\n  }\n\n  .heroContent {\n    padding: 40px 20px;\n    text-align: center;\n    background-image: url(\"../../../../ASSETS/kurr-hero.jpg\");\n  }\n}\n\n.heroTitle {\n  font-size: 2rem;\n  margin-bottom: 10px;\n}\n\n.heroSubTitle {\n  font-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
