import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import FAQ from "../../../../COMPONENTS/FAQ";
import patientsImg from "./../../../../ASSETS/ucct-patients.webp";

const VolunteersSection = () => {
  return (
    <Container className="volunteer-section">
      <Row className="g-0">
        <Col xs={12} md={6} className="leftVolunteer">
          <img alt="Patients" src={patientsImg} className="contactImage" />
        </Col>
        <Col xs={12} md={6} className="rightVolunteer">
          <h2 className="rightVolunteerTitle">
            Volunteers frequently asked questions.
          </h2>
          <FAQ />
        </Col>
      </Row>
    </Container>
  );
};

export default VolunteersSection;
