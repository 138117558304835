import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import quotes from "../../../../ASSETS/quotes.png";
import testimonyImg from "../../../../ASSETS/testimonyimage.jpg";
const TestimonialSection = () => {
  return (
    <Container fluid className="testimony-container">
      {/* Background Image */}
      <div className="testimony-background-image" />
      {/* Overlay */}
      <div className="quotes-overlay" />

      {/* Content Row */}
      <Row className="h-100 align-items-center justify-content-center">
        <Col xs={12} className="physicianQuotesCard">
          <div className="leftBg">
            <img
              className="testimonial-qoutes-image"
              src={quotes}
              alt="Testimonial testimony Icon"
            />

            <h4 className="quoteText">
              “When evaluating a transaction in the urgent care market, buyers
              look for centers that are profitable, that can sustain and grow
              that profit, and sellers are trying to differentiate themselves
              from other centers. Having a clinical trials program is a way to
              accomplish both.”
            </h4>

            <div className="testifier_section">
              <img
                className="testifier-image"
                src={testimonyImg}
                alt="Blayne Rush"
              />
              <div className="testifier-name-section">
                <h4 className="testifier">Blayne Rush</h4>
                <h4 className="testifier-role">
                  President of Ambulatory Alliances
                </h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TestimonialSection;
