import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Ensure this imports your CSS

const InvestigativeSiteSection = () => {
  return (
    <Container className="investigativeSiteSection">
      <Row className="h-100 align-items-center">
        <div className="investigativeSiteHeading">
          Urgent Care Clinical Trials – Investigative Site Network
        </div>
        <div className="investigativeSiteContent">
          Urgent Care Clinical Trials is a clinical research Site Management
          Organization (SMO) developing the first national Investigative Site
          Network (ISN) specifically geared to the urgent care industry. We not
          only build clinical research sites within urgent care centers, but a
          variety of private practices as well. By partnering with local
          community based, established urgent cares and physician offices, we
          are able to develop clinical research sites within their practices and
          provide all the necessary resources to successfully run clinical
          research such as:
        </div>
      </Row>
    </Container>
  );
};

export default InvestigativeSiteSection;
