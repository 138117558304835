// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form-section {
  color: white;
  min-height: 70vh;
  width: 100%;
  overflow: hidden;
  background-color: rgba(239, 244, 247, 0.4);
}

.contact-content {
  display: flex;
  align-items: start;
  justify-content: start;
  height: 100%;
  text-align: left;
  color: #000000;
  max-width: 100%;
  box-sizing: border-box;
}

.contact-heading {
  line-height: 1.6;
  font-size: 4rem;
  font-weight: 400;
  font-family: "Poppins";
}

.contact-button {
  border: 1px solid black;
  background-color: #364776;
  padding: 1em 2em;
  border-radius: 2em;
  margin: 1em 0;
  font-weight: 600;
  color: white;
}

.contact-button:hover {
  border: 1px solid #f8a41b;
  background: transparent;
  color: #f8a41b;
  transition: 0.3s;
}

.addressTitle {
  font-size: 2.5em;
  font-weight: 400;
  color: #3b3c38;
}

.addressSection {
  margin-top: 2em;
}

@media (max-width: 991px) {
  .contact-section {
    min-height: auto;
  }

  .contact-content {
    padding: 20px;
    text-align: left;
  }
}

.contact-email {
  font-size: 2em;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/CONTACT/SECTIONS/CONTACT/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;AACF;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".contact-form-section {\n  color: white;\n  min-height: 70vh;\n  width: 100%;\n  overflow: hidden;\n  background-color: rgba(239, 244, 247, 0.4);\n}\n\n.contact-content {\n  display: flex;\n  align-items: start;\n  justify-content: start;\n  height: 100%;\n  text-align: left;\n  color: #000000;\n  max-width: 100%;\n  box-sizing: border-box;\n}\n\n.contact-heading {\n  line-height: 1.6;\n  font-size: 4rem;\n  font-weight: 400;\n  font-family: \"Poppins\";\n}\n\n.contact-button {\n  border: 1px solid black;\n  background-color: #364776;\n  padding: 1em 2em;\n  border-radius: 2em;\n  margin: 1em 0;\n  font-weight: 600;\n  color: white;\n}\n\n.contact-button:hover {\n  border: 1px solid #f8a41b;\n  background: transparent;\n  color: #f8a41b;\n  transition: 0.3s;\n}\n\n.addressTitle {\n  font-size: 2.5em;\n  font-weight: 400;\n  color: #3b3c38;\n}\n\n.addressSection {\n  margin-top: 2em;\n}\n\n@media (max-width: 991px) {\n  .contact-section {\n    min-height: auto;\n  }\n\n  .contact-content {\n    padding: 20px;\n    text-align: left;\n  }\n}\n\n.contact-email {\n  font-size: 2em;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
