// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.volunteer-section {
  position: relative; /* Allow absolute positioning of children */
  color: white; /* Default text color */
  height: auto; /* Full height for larger screens */
  width: 100vw;
  overflow: hidden; /* Prevent overflow */
}

.leftVolunteer {
  position: relative; /* To allow positioning */
  width: auto; /* Full viewport width */
  height: auto; /* Maintain aspect ratio */
  overflow: hidden; /* Prevent overflow */
}

.contactImage {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.rightVolunteer {
  background-color: #f9f9f9;
  color: black;
  font-weight: 400;
}

.rightVolunteerTitle {
  padding-left: 1em;
  padding-top: 1em;
  font-size: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/APPLICATION/SECTIONS/VOLUNTEERS/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,2CAA2C;EAC/D,YAAY,EAAE,uBAAuB;EACrC,YAAY,EAAE,mCAAmC;EACjD,YAAY;EACZ,gBAAgB,EAAE,qBAAqB;AACzC;;AAEA;EACE,kBAAkB,EAAE,yBAAyB;EAC7C,WAAW,EAAE,wBAAwB;EACrC,YAAY,EAAE,0BAA0B;EACxC,gBAAgB,EAAE,qBAAqB;AACzC;;AAEA;EACE,WAAW;EACX,YAAY,EAAE,0BAA0B;AAC1C;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".volunteer-section {\n  position: relative; /* Allow absolute positioning of children */\n  color: white; /* Default text color */\n  height: auto; /* Full height for larger screens */\n  width: 100vw;\n  overflow: hidden; /* Prevent overflow */\n}\n\n.leftVolunteer {\n  position: relative; /* To allow positioning */\n  width: auto; /* Full viewport width */\n  height: auto; /* Maintain aspect ratio */\n  overflow: hidden; /* Prevent overflow */\n}\n\n.contactImage {\n  width: 100%;\n  height: auto; /* Maintain aspect ratio */\n}\n\n.rightVolunteer {\n  background-color: #f9f9f9;\n  color: black;\n  font-weight: 400;\n}\n\n.rightVolunteerTitle {\n  padding-left: 1em;\n  padding-top: 1em;\n  font-size: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
