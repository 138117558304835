import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Form, Container, Button, Toast, Spinner } from "react-bootstrap";
import axios from "axios";
import { api } from "../../../CONSTANTS";
import "./index.css";

// Validation schema using Yup
const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  contactType: Yup.string().required("Please select your prospective"),
  message: Yup.string().optional(),
  indication: Yup.string().required("Indication is required"),
  specimenType: Yup.string().required("Specimen type is required"),
  media: Yup.string().required("Media is required"),
  comments: Yup.string().optional(),
});

// Dropdown options
const options = {
  prospective: [
    { value: "", label: "—Please choose an option—" },
    { value: "Patient", label: "Patient" },
    { value: "Sponsor", label: "Sponsor" },
    { value: "CRO", label: "CRO" },
    {
      value: "Physician interested in Research",
      label: "Physician interested in Research",
    },
    { value: "Current Investigator", label: "Current Investigator" },
    { value: "Research Site", label: "Research Site" },
    { value: "Other", label: "Other" },
  ],
};

// Form fields configuration
const formFields = [
  { name: "name", label: "Name (required)", type: "text", required: true },
  { name: "phone", label: "Phone (required)", type: "tel", required: true },
  {
    name: "email",
    label: "Your email (required)",
    type: "email",
    required: true,
  },
  { name: "city", label: "City (required)", type: "text", required: true },
  { name: "state", label: "State (required)", type: "text", required: true },
  {
    name: "contactType",
    label: "I am a prospective (required)",
    type: "dropdown",
    options: options.prospective,
    required: true,
  },
  { name: "message", label: "Message", type: "textarea" },
  {
    name: "indication",
    label: "Indication (required)",
    type: "text",
    required: true,
  },
  {
    name: "specimenType",
    label: "Specimen Type (required)",
    type: "text",
    required: true,
  },
  { name: "media", label: "Media (required)", type: "text", required: true },
  { name: "comments", label: "Comments", type: "textarea" },
];

const ContactForm = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    try {
      await axios.post(api, data);
      setToastMessage("Form submitted successfully!");
      setToastVariant("success");
      reset(); // Reset the form fields
    } catch (error) {
      console.error("Submission error:", error);
      setToastMessage("Error submitting form. Please try again.");
      setToastVariant("danger");
    } finally {
      setShowToast(true);
      setLoading(false); // Stop loading
    }
  };

  const renderField = ({ name, label, type, options }) => (
    <Form.Group controlId={`form${name}`} key={name}>
      <Form.Label>
        {label} {type !== "textarea" && <span className="text-danger">*</span>}
      </Form.Label>
      {type === "textarea" ? (
        <Form.Control
          as="textarea"
          rows={3}
          {...register(name)}
          isInvalid={!!errors[name]}
        />
      ) : type === "dropdown" ? (
        <Form.Select {...register(name)} isInvalid={!!errors[name]}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          type={type}
          {...register(name)}
          isInvalid={!!errors[name]}
        />
      )}
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );

  return (
    <Container className="contactForm p-5">
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="formSection"
      >
        {formFields.map(renderField)}
        <Form.Group>
          <div className="d-flex justify-content-center mt-4 contactButton">
            <Button type="submit" disabled={loading} className="formBtn">
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </div>
        </Form.Group>
      </Form>

      {/* Toast Notification */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={`bg-${toastVariant} text-white position-fixed`}
        style={{ bottom: "20px", right: "20px" }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default ContactForm;
