import React from "react";
import "./index.css";
import HeroSection from "./SECTIONS/HERO";
import StepsSection from "./SECTIONS/STEPS";
import WHySection from "./SECTIONS/WHY";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <StepsSection />
      <WHySection />
    </>
  );
};

export default HomePage;
