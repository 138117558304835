import React from "react";
import "./index.css";
import SponsorHeroSection from "./SECTIONS/HERO";
import WhyParticipateSection from "./SECTIONS/WHY_PARTICIPATE";
import TestimonialSection from "./SECTIONS/TESTIMONY";

const SponsorPage = () => {
  return (
    <>
      <SponsorHeroSection />
      <WhyParticipateSection />
      <TestimonialSection />
    </>
  );
};

export default SponsorPage;
