import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import DropDownCard from "../COMMON/CARD/DROPDOWN CARDS";

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqs = [
    {
      question: (
        <div>
          <span className="numberStyle">1.</span> What is clinical research?
        </div>
      ),
      answer: (
        <div>
          <p>
            Clinical Research is a section of healthcare science which
            determines the effectiveness and safety of devices, medications,
            diagnostic products, and treatments for human use. These may be used
            to treat and/or diagnose symptoms of a disease or medical issue.
          </p>
          <p>
            In clinical research, evidence is collected to establish a
            treatment. The term clinical research refers to the entire
            bibliography of a drug, device, or biologic from its inception in
            the lab through to consumer use.
          </p>
          <p>
            If initial laboratory research is successful, the data is sent to
            the Food and Drug Administration (FDA) for approval to continue
            research. Once approved by the FDA for human testing, a group of
            promising candidates are identified to perform a clinical trial.
          </p>
        </div>
      ),
    },
    {
      question: (
        <div>
          <span className="numberStyle">2.</span> What are clinical trials?
        </div>
      ),
      answer: (
        <div>
          <p>
            Clinical trials are only a small part of the overall research that
            goes into the development of a new treatment. They are intended to
            gather data about the safety and effectiveness of new drugs and
            medications, medical devices, and other diagnostic products.
          </p>
          <p>
            Clinical trials take place with healthy subjects and patients with
            specific health conditions. In preliminary phases, participating
            patients are healthy volunteers who are typically financially
            compensated.
          </p>
          <p>
            The clinical sponsor designs particular trials with physicians,
            called clinical investigators. Once a clinical trial is ready,
            investigators will enroll patients that fit within the scope of the
            trial parameters and collect data based on the treatment.
          </p>
        </div>
      ),
    },
    {
      question: (
        <div>
          <span className="numberStyle">3.</span> What are clinical trial
          phases?
        </div>
      ),
      answer: (
        <div>
          <p>
            <strong>Phase I:</strong> These studies determine drug safety,
            tolerability, pharmacokinetics, and pharmacodynamics. This initial
            phase includes a small group of patients, typically 20-100.
          </p>

          <p>
            <strong>Phase II:</strong> These studies test the ability to produce
            a desired result of a drug or device and typically involve 100-300
            patients.
          </p>

          <p>
            <strong>Phase III:</strong> Designed to determine effectiveness and
            monitor side effects in very large groups, typically hundreds to
            thousands of patients. These trials provide thorough data for the
            FDA.
          </p>

          <p>
            <strong>Phase IV:</strong> Often called “Post Marketing Surveillance
            Trials,” these are conducted after a drug has been approved to
            monitor long-term effectiveness and safety.
          </p>
        </div>
      ),
    },
    {
      question: (
        <div>
          <span className="numberStyle">4.</span> Who can participate?
        </div>
      ),
      answer: (
        <div>
          <p>
            All clinical trials have specific guidelines about who can and
            cannot participate. Inclusion and exclusion criteria often include:
          </p>
          <ul>
            <li>Age</li>
            <li>Gender</li>
            <li>The type and stage of a disease</li>
            <li>Previous treatment history</li>
            <li>Other medical conditions</li>
          </ul>
          <p>
            These criteria ensure participants' safety and help researchers
            gather relevant data.
          </p>
        </div>
      ),
    },
    {
      question: (
        <div>
          <span className="numberStyle">5.</span> What should I expect?
        </div>
      ),
      answer: (
        <div>
          <p>
            <strong>Before Starting:</strong> Research staff will review your
            medical history and eligibility. You’ll receive an informed consent
            form explaining:
          </p>
          <ul>
            <li>The purpose of the clinical trial</li>
            <li>The risks and side effects</li>
            <li>Expected benefits</li>
            <li>Differences from standard medical treatment</li>
            <li>Options available to you</li>
          </ul>
          <p>
            <strong>During The Trial:</strong> Participants are monitored
            closely through exams and tests, often receiving care at no cost.
          </p>
          <p>
            <strong>After The Trial:</strong> A final visit will determine the
            drug’s effectiveness and safety based on collected data.
          </p>
        </div>
      ),
    },
    {
      question: (
        <div>
          <span className="numberStyle">5.</span> Why should I participate?
        </div>
      ),
      answer: (
        <div>
          <p>Participating patients will:</p>
          <ul>
            <li>Have access to the newest treatments and medications.</li>
            <li>Receive close medical oversight throughout the study.</li>
            <li>Gain deeper knowledge of their conditions.</li>
            <li>Receive study-related medical care at no cost.</li>
            <li>Receive compensation for time and travel.</li>
            <li>Help advance medicine for future patients.</li>
          </ul>
        </div>
      ),
    },
  ];

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <Container className="faq-section p-4">
      {faqs.map((faq, index) => (
        <div className="asd">
          <DropDownCard
            key={index}
            question={faq.question}
            answer={faq.answer}
            isActive={activeQuestion === index}
            onClick={() => toggleQuestion(index)}
          />
        </div>
      ))}
    </Container>
  );
};

export default FAQ;
