// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-section {
  margin: 2em 0;
}

.faq-section h2 {
  margin-bottom: 1em;
  color: white; /* Adjust heading color */
}

.numberStyle {
  color: #f8a41b;
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/FAQ/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY,EAAE,yBAAyB;AACzC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".faq-section {\n  margin: 2em 0;\n}\n\n.faq-section h2 {\n  margin-bottom: 1em;\n  color: white; /* Adjust heading color */\n}\n\n.numberStyle {\n  color: #f8a41b;\n  font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
