// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../ASSETS/circle-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-card {
  height: 90vh !important;
  text-align: start;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 57%;
  background-repeat: no-repeat;
  background-position: 100% 250%;
  margin-bottom: 2em;
  border: none;
}

.why-card-title {
  color: #364776;
  font-size: 2em;
}

.why-icon {
  font-size: 2em;
  align-items: start;
  background-color: #ffffff;
  color: #f8a41b;
  padding-bottom: 1em;
}
.why-card-content {
  font-size: 1em;
  margin-top: 1em;
}

@media (max-width: 768px) {
  .why-card {
    height: auto !important;
    text-align: start;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 47%;
    background-repeat: no-repeat;
    background-position: 99% -100%;
  }

  .why-card-title {
    color: #364776;
    font-size: 2em;
  }

  .why-icon {
    font-size: 2em;
    align-items: start;
    background-color: #ffffff;
    color: #f8a41b;
    padding-bottom: 1em;
  }
  .why-card-content {
    font-size: 1em;
    margin-top: 1em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/COMMON/CARD/WHY CARDS/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,yDAAyD;EACzD,oBAAoB;EACpB,4BAA4B;EAC5B,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;IACE,uBAAuB;IACvB,iBAAiB;IACjB,yDAAyD;IACzD,oBAAoB;IACpB,4BAA4B;IAC5B,8BAA8B;EAChC;;EAEA;IACE,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;EACrB;EACA;IACE,cAAc;IACd,eAAe;EACjB;AACF","sourcesContent":[".why-card {\n  height: 90vh !important;\n  text-align: start;\n  background-image: url(\"../../../../ASSETS/circle-bg.png\");\n  background-size: 57%;\n  background-repeat: no-repeat;\n  background-position: 100% 250%;\n  margin-bottom: 2em;\n  border: none;\n}\n\n.why-card-title {\n  color: #364776;\n  font-size: 2em;\n}\n\n.why-icon {\n  font-size: 2em;\n  align-items: start;\n  background-color: #ffffff;\n  color: #f8a41b;\n  padding-bottom: 1em;\n}\n.why-card-content {\n  font-size: 1em;\n  margin-top: 1em;\n}\n\n@media (max-width: 768px) {\n  .why-card {\n    height: auto !important;\n    text-align: start;\n    background-image: url(\"../../../../ASSETS/circle-bg.png\");\n    background-size: 47%;\n    background-repeat: no-repeat;\n    background-position: 99% -100%;\n  }\n\n  .why-card-title {\n    color: #364776;\n    font-size: 2em;\n  }\n\n  .why-icon {\n    font-size: 2em;\n    align-items: start;\n    background-color: #ffffff;\n    color: #f8a41b;\n    padding-bottom: 1em;\n  }\n  .why-card-content {\n    font-size: 1em;\n    margin-top: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
