// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question {
  font-size: 1.5em;
}
.answer {
  font-size: 1em;
}
.no-border {
  border: none; /* Remove border from the card */
}

.card-body {
  border: none; /* Remove border from card body */
}

.card {
  background-size: cover;
  background-position: center;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/COMMON/CARD/DROPDOWN CARDS/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY,EAAE,gCAAgC;AAChD;;AAEA;EACE,YAAY,EAAE,iCAAiC;AACjD;;AAEA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":[".question {\n  font-size: 1.5em;\n}\n.answer {\n  font-size: 1em;\n}\n.no-border {\n  border: none; /* Remove border from the card */\n}\n\n.card-body {\n  border: none; /* Remove border from card body */\n}\n\n.card {\n  background-size: cover;\n  background-position: center;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
