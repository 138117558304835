import React from "react";
import "./index.css";
import ContactSection from "./SECTIONS/CONTACT";
import ResponsibilitesHeroSection from "./SECTIONS/HERO";
import ResponsibilitiesStatements from "./SECTIONS/STATEMENTS";

const ResponsibilitesPage = () => {
  return (
    <>
      <ResponsibilitesHeroSection />
      <ResponsibilitiesStatements />
      <ContactSection />
    </>
  );
};

export default ResponsibilitesPage;
