import React from "react";
import "./index.css";
import PhysicianHeroSection from "./SECTIONS/HERO";
import WhyParticipateSection from "./SECTIONS/WHY_PARTICIPATE";
import WhyPartnerSection from "./SECTIONS/WHY_PARTNER";
import TestimonialSection from "./SECTIONS/TESTIMONY";

const PhysicianPage = () => {
  return (
    <>
      <PhysicianHeroSection />
      <WhyParticipateSection />
      <WhyPartnerSection />
      <TestimonialSection />
    </>
  );
};

export default PhysicianPage;
