import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPieChart, faSignal } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import ucctBp from "../../../../ASSETS/kurr-bp.jpg";
const AssessmentStatements = () => {
  return (
    <Container fluid className="assessment-text">
      <Row className="h-100">
        <Col xs={12} md={6}>
          <img alt="Patients" src={ucctBp} className="assessmentImage" />{" "}
        </Col>
        <Col xs={12} md={6}>
          <div className="assesmentStatementCard">
            <div className="assessment-icon-wrapper">
              <FontAwesomeIcon icon={faSignal} className="assessmentIcon" />
            </div>
            <div className="assessmentDescription">
              The first action we will take with a new perspective partnership
              is assess the physician and practice to determine if clinical
              research is an appropriate fit. We gather information in regards
              to the scope and potential impact of gaps or deficiencies
              concerning the delivery of care in clinical research and identity
              constructive matrices for a successful trial in your specific
              practice. The processes and techniques are used to gather and
              analyze the centers equipment, physical structure, layout,
              research history, partnerships, time commitment, patient
              availability, and all other resources necessary to ensure the
              proper execution of a clinical trial.
            </div>
          </div>
          <div className="assesmentStatementCard">
            <div className="assessment-icon-wrapper">
              <FontAwesomeIcon icon={faPieChart} className="assessmentIcon" />
            </div>
            <div className="assessmentDescription">
              After the initial assessment and we have collectively identified a
              Principal Investigator (PI), who is the lead physician involved
              with the clinical trial and is responsible for it’s oversight, we
              will discuss with the practice and physician how our program works
              in more detail. Items such as expectations, time commitments,
              responsibilities, and typically how the program starts. Next, we
              would discuss necessary support staff roles and responsibilities
              that will be involved in the clinical trial process from the
              practice and from UCC Trials. Once all the information is reviewed
              and it’s mutually determined to proceed with the program, UCC
              Trials will present the physician and practice with a contract for
              review and ultimately initiate the program.
            </div>{" "}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AssessmentStatements;
