import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFoundPage from "./PAGES/404";
import HomePage from "./PAGES/HOME";
import PatientsPage from "./PAGES/PATIENTS";
import MissionStatementPage from "./PAGES/MISSION_STATEMENT";
import ApplicationsPage from "./PAGES/APPLICATION";
import ResponsibilitesPage from "./PAGES/RESPONSIBILITIES";
import AssessmentPage from "./PAGES/ASSESSMENT";
import ImplementationPage from "./PAGES/IMPLEMENTATION";
import ManagementPage from "./PAGES/MANAGEMENT";
import PhysicianPage from "./PAGES/PHYSICIANS";
import ContactsPage from "./PAGES/CONTACT";
import SponsorPage from "./PAGES/SPONSOR";

function App() {
  return (
    <div className="app-container" style={{ width: "100%" }}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/patients" element={<PatientsPage />} />
          <Route path="/physicians" element={<PhysicianPage />} />{" "}
          <Route path="/sponsors" element={<SponsorPage />} />
          <Route path="/mission-statement" element={<MissionStatementPage />} />
          <Route path="/job-application" element={<ApplicationsPage />} />
          <Route path="/responsibilities" element={<ResponsibilitesPage />} />
          <Route path="/assessment" element={<AssessmentPage />} />
          <Route path="/implementation" element={<ImplementationPage />} />
          <Route path="/management" element={<ManagementPage />} />
          <Route path="/contact" element={<ContactsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
