import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Ensure this imports your CSS
import CustomCard from "../../../../COMPONENTS/COMMON/CARD/BASIC CARDS";

const stepsData = [
  {
    id: 1,
    title: "Assessment",
    description:
      "We assess physicians and practices for clinical research suitability, identifying gaps and optimizing resources for seamless trial execution",
    btnLink: "/assessment",
  },
  {
    id: 2,
    title: "Implementation",
    description:
      "We establish the research office, hire UCC Trials staff, and ensure comprehensive training for efficient research application preparation",
    btnLink: "/implementation",
  },

  {
    id: 3,
    title: "Management",
    description:
      "We assure clinical trial success through streamlined management, offering comprehensive support for physicians, patients, and sponsors with UCC Trials services.",
    btnLink: "/management",
  },
];

const StepsSection = () => {
  return (
    <Container fluid className="steps">
      <Row className="h-100 align-items-center">
        {stepsData.map((step) => (
          <CustomCard
            key={step.id}
            title={step.title}
            content={step.description}
            btnLink={step.btnLink}
            background={step.background}
          />
        ))}
      </Row>
    </Container>
  );
};

export default StepsSection;
