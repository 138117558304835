// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
  border-radius: 0 !important;
  border-top: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  display: flex;
  flex-direction: column;
  height: 300px !important;
  margin: 0;
  overflow: hidden;
  background-color: #eff4f7 !important;
}

.custom-card-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1em !important;
}

.custom-card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.custom-card-subtitle {
  margin-bottom: 1em;
  padding: 1em;
  flex-grow: 1;
  overflow: hidden;
}

.stepsButton {
  border: 1px solid black;
  background: transparent;
  padding: 1em;
  border-radius: 2em;
  margin: 1em 0;
}

.stepsButton:hover {
  border: 1px solid black;
  background: #364776;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/COMMON/CARD/BASIC CARDS/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,qCAAqC;EACrC,uCAAuC;EACvC,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,SAAS;EACT,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".custom-card {\n  border-radius: 0 !important;\n  border-top: 1px solid #ddd !important;\n  border-right: 1px solid #ddd !important;\n  display: flex;\n  flex-direction: column;\n  height: 300px !important;\n  margin: 0;\n  overflow: hidden;\n  background-color: #eff4f7 !important;\n}\n\n.custom-card-body {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n  padding: 1em !important;\n}\n\n.custom-card-title {\n  font-size: 1.5rem;\n  margin-bottom: 0.5em;\n  text-transform: uppercase;\n}\n\n.custom-card-subtitle {\n  margin-bottom: 1em;\n  padding: 1em;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.stepsButton {\n  border: 1px solid black;\n  background: transparent;\n  padding: 1em;\n  border-radius: 2em;\n  margin: 1em 0;\n}\n\n.stepsButton:hover {\n  border: 1px solid black;\n  background: #364776;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
