import React from "react";
import "./index.css";
import ContactSection from "./SECTIONS/CONTACT";
import ImplementationHeroSection from "./SECTIONS/HERO";
import ImplementationStatements from "./SECTIONS/STATEMENTS";

const ImplementationPage = () => {
  return (
    <>
      <ImplementationHeroSection />
      <ImplementationStatements />
      <ContactSection />
    </>
  );
};

export default ImplementationPage;
