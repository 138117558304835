import React from "react";
import "./index.css";
import ContactSection from "./SECTIONS/CONTACT";
import ContactHeroSection from "./SECTIONS/HERO";

const ContactsPage = () => {
  return (
    <>
      <ContactHeroSection />
      <ContactSection />
    </>
  );
};

export default ContactsPage;
