import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import sponsors from "../../../../ASSETS/sponsors.jpg";
import patients from "../../../../ASSETS/ucct-patients.webp";
import scientists from "../../../../ASSETS/ucct-scientists.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldHalved,
  faStethoscope,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const WhySection = () => {
  return (
    <Container className="whySection">
      <Row>
        <Col xs={12} className="whyTitleSection">
          <h3 className="whyTitle">Urgent Care Clinical Trials</h3>
          <p className="whySubtitle">Join Our Investigative Site Network</p>
        </Col>
      </Row>
      <Row className="g-0">
        <Col xs={12} md={6}>
          <img alt="Sponsors" src={sponsors} className="whyImage" />
        </Col>
        <Col xs={12} md={6}>
          <div className="text">
            <h3 className="title">Sponsors</h3>
            <p className="subtitle">
              <FontAwesomeIcon icon={faShieldHalved} className="whyBg" />
              Our expanding Investigative Site Network and exceptional research
              staff consistently deliver our Sponsors a seamless experience in
              the advancement of clinical research through quality data,
              reliability, and fast enrollment.
            </p>
            <div className="btnSection">
              <a href="/sponsors">
                <button className="whyButton">Learn More</button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="g-0">
        <Col xs={12} md={6}>
          <div className="text">
            <h3 className="title">Patients</h3>
            <p className="subtitle">
              <FontAwesomeIcon icon={faUser} className="whyBg" />
              We improve our Patients' lives by giving them access to the most
              advanced medical treatments, superior healthcare oversight, and
              higher education about their medical conditions. Find a clinical
              trial near you.
            </p>
            <div className="btnSection">
              <a href="/patients">
                <button className="whyButton">Learn More</button>
              </a>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <img alt="Patients" src={patients} className="whyImage" />
        </Col>
      </Row>
      <Row className="g-0">
        <Col xs={12} md={6}>
          <img alt="Physicians" src={scientists} className="whyImage" />
        </Col>
        <Col xs={12} md={6}>
          <div className="text">
            <h3 className="title">Physicians</h3>
            <p className="subtitle">
              <FontAwesomeIcon icon={faStethoscope} className="whyBg" />
              We provide our Physicians with the highest quality clinical
              support and an opportunity to directly aid in the development of
              the most advanced treatments. Physicians choose to participate in
              clinical research for many reasons.
            </p>
            <div className="btnSection">
              <a href="/physicians">
                <button className="whyButton">Learn More</button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhySection;
