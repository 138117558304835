// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assessment-text {
  position: relative;
  overflow: hidden;
}

.assesmentStatementCard {
  color: black;
  display: flex;
  align-items: start;
  margin: 1em;
}

.assessmentIcon {
  font-size: 2em;
  color: #f8a41b;
  padding: 1em;
}

.assessment-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #f8a41b;
  border-radius: 50%;
  transition: border-color 0.3s;
  margin-right: 15px;
}

.assessmentImage {
  width: 100%;
  /* height: 100%; */
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/ASSESSMENT/SECTIONS/STATEMENTS/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".assessment-text {\n  position: relative;\n  overflow: hidden;\n}\n\n.assesmentStatementCard {\n  color: black;\n  display: flex;\n  align-items: start;\n  margin: 1em;\n}\n\n.assessmentIcon {\n  font-size: 2em;\n  color: #f8a41b;\n  padding: 1em;\n}\n\n.assessment-icon-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 80px;\n  height: 80px;\n  border: 1px solid #f8a41b;\n  border-radius: 50%;\n  transition: border-color 0.3s;\n  margin-right: 15px;\n}\n\n.assessmentImage {\n  width: 100%;\n  /* height: 100%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
