// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investigativeSiteSection {
  text-align: center;
  padding: 2em;
}
.investigativeSiteHeading {
  color: #364776;
  font-weight: 700;
  font-size: 2em;
  font-family: "Poppins";
  line-height: 2.5em;
}
.investigativeSiteContent {
  padding: 0 5em 5em 5em !important;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/PATIENTS/SECTIONS/INVESTIGATIVE_SITE_NETWORK/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".investigativeSiteSection {\n  text-align: center;\n  padding: 2em;\n}\n.investigativeSiteHeading {\n  color: #364776;\n  font-weight: 700;\n  font-size: 2em;\n  font-family: \"Poppins\";\n  line-height: 2.5em;\n}\n.investigativeSiteContent {\n  padding: 0 5em 5em 5em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
