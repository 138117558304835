import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

import ucctMeetingImg from "../../../../ASSETS/ucct-meeting2.jpg";

const ManagementStatements = () => {
  const statements = [
    {
      title: "Research, Development & Acquisition",
      description:
        "As part of our initial assessment of your practice, UCC Trials will help to determine what type of clinical trials are most appropriate. It’s always best to pursue clinical trials that are within the staff's capabilities and the practice’s patient population. We complete a rigorous trial application process for each clinical research study which includes a site feasibility, aggregating de-identified data about the patient population, and past performance matrices. Our trial development team of researchers are consistently bringing clinical trials to all of our sites on a regular basis.",
    },
    {
      title: "Site Management",
      description:
        "UCC Trials will provide complete onsite management for each study from application to study close out. We will facilitate every monitor visit, maintain study drug and supplies, explain consent forms to patients, re-consent patients when necessary, document SAE’s and IND safety reports, document amendments, ensure proper data collection, provide scheduling for all patients, and more to ensure enrollment goals are met.",
    },
    {
      title: "Contract Negotiations",
      description:
        "UCC Trials will negotiate and renegotiate if necessary each contract for each study to ensure appropriate contractual relationships for each physician and make sure research site staff comply with contractual obligations.",
    },
    {
      title: "Financials",
      description:
        "UCC Trials will monitor and over see all financial aspects of each study including budget negotiations, invoicing, billing, account reconciliation, patient stipends, financial models, and more. Physicians will not have to worry about any financial aspects of any study, making it easier to concentrate on patient care.",
    },
    {
      title: "Regulatory Compliance",
      description:
        "Every research site partnered with UCC Trials is supported by executive staff in addition to research staff at the site to ensure regulatory compliance, FDA compliance, and IRB approval. At the start of each trial there is an extensive regulatory packet that much be completed in order to begin the study. UCC Trials will ensure each regulatory packet is completed properly, along with each FDA 1572, financial disclosure forms, IRB documentation, lab documentation, consent and assent forms, amendments, and more.",
    },
    {
      title: "Marketing",
      description:
        "Although the primary source of patients is the physicians practice, we will design, develop, and deploy each marketing campaign as well as submit for IRB approval before each deployment when appropriate. Each marketing campaign will not only bring awareness of each trial being conducted at the research site, but will be an opportunity for your practice to be seen as a physicians office providing the newest and most cutting edge medications and treatment.",
    },
    {
      title: "Research Site Management",
      description:
        "Every study conducted requires a tremendous amount of time, admin, and effort. UCC Trials has in place a robust workforce to ensure each trial get the appropriate attention at each location. With so much happening at any given moment at you practice, it’s truly difficult to take on any ancillary programs. This is why UCC Trials has staff not only remotely at our corporate, but local as well. We employ regional managers, regulatory personnel, clinical research coordinators, and research assistants to work at your private practice to make sure no detail is missed through out the clinical research process. And to ensure physicians can concentrate on patient care!",
    },
    {
      title: "Clinical Trial Management System",
      description:
        "All of our data and processes are manages by our propriety clinical trial management system which electronically captures all aspects of each study such as patient management, recruitment, study data, source documents, accounting and budgeting, financial models, patient stipends, document storage and archiving, patient scheduling and reminders, and so much more! Our system is Title 21 CFR Part 11 Compliant and has triple redundant backups so our data will never be lost.",
    },
    {
      title: "Quality Assurance",
      description:
        "Providing quality is our number on goal. We are constantly monitoring our quality indicators and providing continuing education and training for all of our research staff. We strive to provide the highest quality healthcare to our patients, highest quality data to our sponsors, and highest quality support to our physicians.",
    },
  ];

  return (
    <Container fluid className="management-text">
      <Row className="h-100">
        <Col xs={12} md={6}>
          <img
            alt="Patients"
            src={ucctMeetingImg}
            className="managementImage"
          />
        </Col>
        <Col xs={12} md={6} className="management-scrollable">
          {statements.map((statement, index) => (
            <div className="managementStatementCard" key={index}>
              <div className="management-icon-wrapper">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="managementIcon"
                />
              </div>
              <div className="managementDescription">
                <span className="managementDescriptionTitle">
                  {statement.title}
                </span>
                {statement.description}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default ManagementStatements;
